import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { formatDate, formatDatetime } from './../../../../../utils/dateformat.js'

const LabelRow = props => (
    <tr>
        <td>
            <select className="form-control" disabled={props.readOnly} required onChange={(e) => props.onChangeProperty(e.currentTarget.value, 'label', props.index)}>
                {props.labels.length && props.labels.map((item) => {
                    return <option value={item.id} selected={props.label.label == item.id}>{item.title}</option>
                })}
            </select>
        </td>
        <td>
            <DatePicker
                value={formatDate(props.label.from)}
                className={"form-control"}
                required
                onChange={(date) => props.onChangeProperty(formatDatetime(date), 'from', props.index)}
            />
        </td>
        <td>
            <DatePicker
                value={formatDate(props.label.to)}
                className={"form-control"}
                required
                onChange={(date) => props.onChangeProperty(formatDatetime(date), 'to', props.index)}
            />
        </td>
        <td>
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => props.onDelete(props.index)}>x</button>
        </td>
    </tr>
);

export default LabelRow;