export class BaseProvider {

    static sendToServerByPost(postData, url, onSuccess, onError, onLoad) {
        const authToken = localStorage.getItem('authToken');

		try {
			$.post({
				url: url,
				data: JSON.stringify(postData),
                dataType: 'json',
				beforeSend: (xhr) => {
					
					if (authToken) {
						xhr.setRequestHeader("Authorization", "Bearer " + authToken);


					}
					else {
						xhr.setRequestHeader("Authorization", "Bearer ");
					}
				},
				
				contentType: 'application/json',
			})
				.done(function (response) {
					if (onSuccess)
						onSuccess(response);
				})

				.fail(function (xhr, status, error) {
					if (xhr.responseText=='Unauthorized')//unauthorized
					{
						localStorage.removeItem("authToken");
						
					}
                    const responseObject = xhr.responseJSON;

                    if (onError) onError(responseObject);

				});
			if (onLoad)
				onLoad();
		}
		catch (error) {
			if (onError) onError(error);
		}
	}

	static sendToServerByGet(url, onSuccess, onError,onLoad) {

		const authToken = localStorage.getItem('authToken');
		try {
			$.getJSON({
				url: url,
				beforeSend: (xhr) => {
					if (authToken) {
						xhr.setRequestHeader("Authorization", "Bearer " + authToken);
						//	xhr.setRequestHeader("WWW - Authenticate", "FormBased");
					}
					else {
						xhr.setRequestHeader("Authorization", "Bearer ");
					}
				},
				contentType: "application/json; charset=utf-8",
                
			})
				.done(function (response) {
					if (onSuccess)
						onSuccess(response);
				})
				.fail(function (xhr, status, error) {

					console.log(xhr);
					if (xhr.responseText == 'Unauthorized')//unauthorized
					{
						localStorage.removeItem("authToken");						
					}
					if (onError) onError(error);
				});
			if (onLoad)
				onLoad();
		}
		catch (error) {
			if (onError) onError(error);
		}
	}
}