import React, { Component } from 'react';
import { UserProvider } from '../../../providers/userprovider.js';

export default class LoginForm extends Component {

	onSubmitLogin = (e) => {
		e.preventDefault();

		const username = event.target.username.value;
		const password = event.target.password.value;

		UserProvider.Login({ userName: username, password: password }, (data) => {
	
		});
	}

	render() {

		return (
			<div className="login-form">
				<form onSubmit={(e) => this.onSubmitLogin(e)}>
					<h2 className="text-center">Log in</h2>
					<div className="form-group">
						<label className="control-label" htmlFor="username"><b>Username</b></label>
						<input type="text" placeholder="Enter Username" className="form-control" name="username" required />
					</div>
					<div className="form-group">
						<label className="control-label" htmlFor="password"><b>Password</b></label>
						<input type="password" placeholder="Enter Password" className="form-control" name="password" required />
					</div>
					<div className="form-group">
						<button className="btn btn-primary" style={{width:'100%'}} type="submit">Přihlásit</button>
					</div>
				</form>
			</div>
		);
	}
}