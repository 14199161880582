import React, { Component } from 'react';

import { ActivityProvider } from '../../providers/activityprovider.js';
import PageBar from './../../components/common/paging/pageBar.jsx';
import { Loading } from './../../components/common/loadings/index.jsx';
import { formatDatetime } from './../../utils/dateformat.js';

export default class ActivityList extends Component {

    state = {
        items: [],
        pageIndex: 1,
        pageCount: 1,
        isLoading: true
    }

    componentWillMount() {
        this.loadData();
    }

    loadData() {
        const { pageIndex, ordering } = this.state;

        const pageModel = { pageIndex: pageIndex, ordering: ordering };

        ActivityProvider.getActivities(pageModel, (response) => {
            this.setState({
                isLoading: false,
                items: response.data
            });
        });
    }

    onChangePage = (page) => {
        this.setState({ pageIndex: page, isLoading: true }, () => this.loadData());
    }


    render() {
        const { items, pageCount, pageIndex, isLoading } = this.state;

        const pagingObject = {
            data: items,
            activePage: pageIndex,
            pageCount: pageCount
        };

        return (
            <Loading isLoading={isLoading}>
                <div>
                    {items && items.length > 0 ? <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Typ</th>
                                <th scope="col">Popis</th>
                                <th scope="col">Výsledek</th>
                                <th scope="col">Vytvořeno</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.type}</td>
                                        <td>{item.desc}</td>
                                        <td>{item.resultType}</td>
                                        <td>{formatDatetime(item.created)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table> : <p>Aktivity jsou prázdné</p>}
                    <PageBar data={pagingObject} onPageChanged={(page) => { this.onChangePage(page) }} />
                </div>
            </Loading>);
    }
}