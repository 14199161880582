import moment from 'moment';

function formatDate(dateString) {
    return moment(dateString).format('YYYY-MM-DD');
}

function formatDateCZ(dateString) {
	return moment(dateString).format('DD.MM.YYYY');
}

function formatDatetime(dateString) {
    return moment(dateString).format('YYYY-MM-DD HH:MM:SS')
}


export {
    formatDate,
	formatDatetime,
	formatDateCZ
}