import React, { Component } from 'react';
import PromotionsTable from '../tables/promotionsTable.jsx';

export default class PromotionsTab extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { selectedPromotions, onChange } = this.props;

        return (
                <PromotionsTable selectedPromotions={selectedPromotions} onChange={onChange} />
        );
    }
}