import React, { Component } from 'react';

import PageBar from './../../components/common/paging/pageBar.jsx';
import { Loading } from './../../components/common/loadings/index.jsx';
import { AppStore } from '../../store/appstore.js';
import OrderDetail from './orderdetail.jsx';
import { OrderProvider } from '../../providers/orderprovider.js';
import { DeliveryProvider } from '../../providers/deliveryprovider.js';


export default class OrderList extends Component {
	constructor(props) {
		super(props);
		this.numberField = React.createRef();
	}

	state = {
		items: [],
		pageIndex: 1,
		ordering: "title",
		selectedItem: null,
		pageCount: 1,
		isLoading: true,
		filter: {
			status: '',
			deliveryMethodId: null,
			orderNumber: null
		}
	}


	componentWillMount() {
		this.loadData();
	}

	componentDidMount() {
		//"/Home/Orders/72"
		var path = this.props.location.pathname;
		var that = this;
		var arr = path.split("/Orders/");
		if (arr.length > 1) {
			var id = arr[arr.length - 1];
			OrderProvider.getOrder(id, (data) => {
				this.setState({ selectedItem: data });
			});
		}
		DeliveryProvider.getDeliveries((data) => {
			that.setState({ deliveries: data.data });
		});
		AppStore.on('Orders.refresh', () => {
			this.loadData();
		});
		AppStore.on('Orders.Update', (response) => { //todo paging model
			this.setState({
				items: response.data,
				pageCount: response.pageCount,
				isLoading: false
			});
		});
	}

	getDeliveryName(id) {
		if (this.state.deliveries) {
			let delivery = this.state.deliveries.find((item) => {
				return parseInt(item.code) === id;
			});
			return (delivery) ? delivery.title.replace('Doprava ', '') : '';
		}
		else {
			return 'načítám...';
		}
	}

	loadData(filter) {
		const { pageIndex, ordering } = this.state;
		if (!filter) {
			filter = this.state.filter;
		}
		const pageModel = { PageIndex: pageIndex, Ordering: ordering, Status: filter.status, DeliveryMethodId: filter.deliveryMethodId, OrderNumber: filter.orderNumber };
		var that = this;
		OrderProvider.getOrders(pageModel, () => {
			that.setState({
				pageIndex: pageIndex,
				filter: filter,
				isLoading: false
			});
		});
	}

	handleEdit = (e, item) => {
		OrderProvider.getOrder(item.id, (data) => {
			this.setState({ selectedItem: data });
		});
	}

	handleCloseDialog = () => {
		this.setState({ selectedItem: null });
	}

	handleCloseDialog = () => {
		this.setState({ selectedItem: null });
	}

	onChangePage = (page) => {
		this.setState({ pageIndex: page, isLoading: true }, () => this.loadData());
	}

	onClickSort = (column) => {
		this.setState({ ordering: column }, this.loadData());
	}

	onClickStatus = (state) => {
		let filter = this.state.filter;
		filter.status = state;
		this.loadData(filter);
	}

	onChangeNumber = (number) => {
		let filter = this.state.filter;
		filter.orderNumber = parseInt(number);
		this.setState({ filter: filter });
	}

	submit = () => {
		this.loadData(this.state.filter);
	}

	onClickDelivery = (deliveryId) => {
		let filter = this.state.filter;
		filter.deliveryMethodId = deliveryId;
		this.loadData(filter);
	}

	render() {
		const { items, selectedItem, pageCount, pageIndex, isLoading } = this.state;

		const pagingObject = {
			data: items,
			activePage: pageIndex,
			pageCount: pageCount,
		};

		return (
			<Loading isLoading={isLoading}>
				<div>
					{selectedItem ? <OrderDetail item={selectedItem} onClose={() => this.handleCloseDialog()} /> : null}
					<div className="row">
						<div className="form-group col-sm-4">
							<label className=" col-form-label">Objednávka</label>
							<div >
								<input onKeyPress={()=>this.submit()} value={this.state.filter.orderNumber} type="number" onChange={(e) => this.onChangeNumber(e.target.value)} className="col-sm-4 form-control" />
								<button type="button" className="btn btn-default" onClick={() => this.submit()}  >OK</button>
							</div>
						</div>

						<div className="form-group col-sm-4">
							<label className=" col-form-label">Stav</label>
							<div >
								<select onChange={(e) => this.onClickStatus(e.target.value)} className="form-control">
									<option value="">všechny</option>
									<option value="blocked">blokovaná</option>
									<option value="open">otevřená</option>
									<option value="shipping">zpracovává se</option>
									<option value="shipped">odeslaná</option>
									<option value="delivered">doručena</option>
									<option value="returned">vráceno</option>
									<option value="cancelled">stornováno</option>
								</select>
							</div>
						</div>
						<div className="form-group col-sm-4">
							<label className=" col-form-label">Doprava</label>
							<div >
								<select onChange={(e) => this.onClickDelivery(e.target.value)} className="form-control">
									<option value="">všechny</option>
									{(this.state.deliveries) ? this.state.deliveries.map((item) =>
										<option value={item.code}>{item.title}</option>
									) : null}
								</select>
							</div>
						</div>
					</div>
					<table className="table" >
						<thead>
							<tr>
								<th scope="col">Number</th>
								<th scope="col">Mall Id</th>
								<th scope="col">Přijato</th>
								<th scope="col">Zákazník</th>
								<th scope="col">K platbě</th>
								<th scope="col">Plánované doručení</th>
								<th scope="col">Status</th>
								<th scope="col">Doprava</th>
								<th scope="col">&nbsp;</th>
							</tr>
						</thead>
						<tbody>
							{items && items.map((item) => {
								return (
									<tr>
										<td>{item.displayExternalOrderId}</td>
										<td>{item.id}</td>
										<td>{item.displayDateOpen}</td>
										<td>{item.customer}</td>
										<td>{item.displayCod}</td>

										<td>{item.displayShipDate}</td>
										<td>{(item.confirmed) ? (item.status == 'delivered') ? <span>{item.displayStatus}<br />{item.displayDeliveryAt}</span> : item.displayStatus : 'nepotrvrzená'}</td>

										<td>{this.getDeliveryName(item.deliveryMethodId)}</td>
										<td>
											<button type="button" className="btn btn-default" onClick={(e) => this.handleEdit(e, item)}>Detail</button>
											<a href={'/api/order/print?id=' + item.id} className="btn btn-primary" target="print">Tisk</a>

										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<PageBar data={pagingObject} onPageChanged={(page) => { this.onChangePage(page) }} />
				</div>
			</Loading>);
	}
}