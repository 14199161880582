import { BaseProvider } from './baseprovider';
import { Constants } from './../store/constants';
import { AppStore } from './../store/appstore';

export class ProductProvider {

    static search(catalogueNumber) {
        BaseProvider.sendToServerByGet(Constants.Url.Search + "?catalogueNumber=" + catalogueNumber, (data) => {
            AppStore.emit('FeedItem.LoadList', data);
        });
	}
    static candidate() {
        BaseProvider.sendToServerByGet(Constants.Url.Candidate, (data) => {
            AppStore.emit('FeedItem.LoadCandidate', data);
        });
    }
	static searchbyeanlist(list) {
		BaseProvider.sendToServerByPost(list, Constants.Url.SearchByEanList, (data) => {
			AppStore.emit('FeedItem.LoadList', data);
		});
	}

	static searchbycodelist(list) {
		BaseProvider.sendToServerByPost(list, Constants.Url.SearchByCodes, (data) => {
			AppStore.emit('FeedItem.LoadList', data);
		});
	}

	static productIds(callback) {
		BaseProvider.sendToServerByGet( Constants.Url.ProductIds, (data) => {
			callback(data);
		});
	}

    static createOnMall(item) {
        BaseProvider.sendToServerByPost(item, Constants.Url.CreateOnMall, (data) => {

        });
    }

	static getCategories(forceUpdate, callBack) {
		BaseProvider.sendToServerByGet(Constants.Url.Categories + "?force=" + forceUpdate, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

    static getMenuItems(callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.MenuItems, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }
    static getMenuItemDetail(id,callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.MenuItemDetail + "?id=" + id, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }
    static getParametersValues(categoryId, mId, callBack) {
        const url = Constants.Url.ParametersValues + "?categoryId=" + categoryId + "&paramId=" + mId;

        BaseProvider.sendToServerByGet(url, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }


    static getParametersValues(categoryId, mId, callBack) {
        const url = Constants.Url.ParametersValues + "?categoryId=" + categoryId + "&paramId=" + mId;

        BaseProvider.sendToServerByGet(url, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

    static getParameters(categoryId, callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.Parameters + "?categoryId=" + categoryId, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

    static getLabels(callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.Labels, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

    static getProducts(pageRequest, callBack) {
        BaseProvider.sendToServerByPost(pageRequest, Constants.Url.Products, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

    static updateStore(callBack, errorBack, proccessBack) {
        BaseProvider.sendToServerByGet(Constants.Url.UpdateStore, (data) => {
            if (callBack) {
                callBack(data);
            }
        }, null, () => {
            if (proccessBack)
                proccessBack();
        });
	}
	static updateRecomended(callBack, errorBack, proccessBack) {
		BaseProvider.sendToServerByGet(Constants.Url.UpdateRecomendedAll, (data) => {
			if (callBack) {
				callBack(data);
			}
		}, null, () => {
			if (proccessBack)
				proccessBack();
		});
	}


    static getProduct(id, callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.GetProduct + "?id=" + id, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }
    static loadProduct(id, callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.LoadProduct + "?catalogueNumber=" + id, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

    static saveProduct(item, onSuccessCallback, onErrorCallback) {
        BaseProvider.sendToServerByPost(item, Constants.Url.SaveProduct,
            (data) => {
                if (onSuccessCallback) {
                    onSuccessCallback(data);
                }
            }, (error) => {
                if (onErrorCallback) {
                    onErrorCallback(error);
                }
            });
    }

    static activateProduct(id, callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.ActivateProduct + "?id=" + id, (data) => {
            if (callBack) {
                callBack();
            }
        });
    }

    static reloadProduct(id, callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.ReloadProduct + "?catalogueNumber=" + id, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
	}
	//updatedescriptions
	static updateDescriptions( all,id,callBack) {
		BaseProvider.sendToServerByGet(Constants.Url.UpdateDescriptions + "?all=" + all + '&id=' + id, (data) => {
			if (callBack) {
				callBack(data);
			}
		});
	}

	static updatePrice(id, callBack) {
		BaseProvider.sendToServerByGet(Constants.Url.UpdatePrice + "?id=" + id, (data) => {
			if (callBack) {
				callBack(data);
			}
		});
	}
}