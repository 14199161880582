import EnhancedTab from './enhancedTab.jsx'
import ParametersTab from './parametersTab.jsx'
import DimensionsTab from './dimensionsTab.jsx'
import BasicTab from './basicTab.jsx'
import DescriptionTab from './descriptionTab.jsx'
import LabelsTab from './labelsTab.jsx'
import PromotionsTab from './promotionsTab.jsx'

export {
    EnhancedTab,
    ParametersTab,
    DimensionsTab,
    BasicTab,
    DescriptionTab,
    LabelsTab,
    PromotionsTab
}