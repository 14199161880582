import React from 'react';

const DimensionsTab = props => (
	<div className="tab-content">
		<div className="form-group row">
			<label className="col-sm-4 col-form-label">Velikost balíku</label>
            <div className="col-sm-8">
                <select className="form-control" required onChange={(e) => props.onChange(e, 'packageSize')} value={props.item.packageSize}>
					<option value={'smallbox'} >Small Box</option>
					<option value={'bigbox'}>Big box</option>
				</select>
			</div>
		</div>

        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Hmotnost (kg)</label>
            <div className="col-sm-8">
                <input className="form-control" required type="number" value={props.item.dimensions.weight} onChange={(e) => props.onChange(e, 'weight', 'dimensions')} /></div>
		</div>
		<div className="form-group row">
			<label className="col-sm-4 col-form-label">Šířka (cm)</label>
			<div className="col-sm-8">
				<input className="form-control" type="number" value={props.item.dimensions.width} onChange={(e) => props.onChange(e, 'width', 'dimensions')} /></div>
		</div>
    </div>
);


export default DimensionsTab;