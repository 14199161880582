import React, { Component } from 'react';
import { UserProvider } from '../../../providers/userprovider.js';
import ReactDOM from 'react-dom';

export default class LoginPanel extends Component {

    state = {
        user: {},
    }

    loadUserData = () => { //TODO presunout stav user do redux storu, neni treba davat do appstore
        UserProvider.GetUserDetail((data) => {
            this.setState({ user: data });
        });
    }

    componentDidMount() {
        this.loadUserData();
    }

    onClickLogout = () => {
        UserProvider.Logout(() => {
            localStorage.removeItem("authToken");
			window.location = '/';
        });
    }

    render() {
        const { user } = this.state;

        return (
            <React.Fragment>
				{user ?
					<div className="">
                        <span>{user.userName}</span> - <a onClick={this.onClickLogout}>Logout</a>
                    </div> : null}
            </React.Fragment>
        );
    }
}

var element = document.getElementById("app-loginpanel");
if (element) {
	ReactDOM.render(
		
		
		<LoginPanel />, element);
}