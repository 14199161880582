import React, { Component } from 'react';
import LoginPanel from '..//login/loginPanel.jsx';

const AdminLayout = props => (
            <React.Fragment>
              
                {props.children}
            </React.Fragment>
        )

export default AdminLayout;