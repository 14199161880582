import React from 'react';

import { formatDateCZ } from './../../../../utils/dateformat.js';

const DetailTab = props => (
	<div className="tab-content">
        {props.orders && props.orders.length > 0 ? <table className="table">
            <thead>
				<tr>
					<th scope="col">Id</th>
                    <th scope="col">Zákazník</th>
                    <th scope="col">Cena k platbě</th>                    
                    <th scope="col">Datum expedice</th>
                    <th scope="col">Datum doručení</th>                    
                </tr>
            </thead>
            <tbody>
                {props.orders.map((item) => {
                    return (
						<tr>
							<td>{item.id}</td>
                            <td>{item.customer}</td>
                            <td>{item.cod}</td>                            
							<td>{formatDateCZ(item.shipDate)}</td>
							<td>{formatDateCZ(item.deliveredAt)}</td>                                                      
                        </tr>
                    );
                })}
            </tbody>
        </table> : <p>Zápočet je prázdný</p>}
	</div>
)

export default DetailTab;
