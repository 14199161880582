import React, { Component } from 'react';
import { ProductProvider } from '../../providers/productprovider.js';

export default class ProductCandidates extends Component {

	
	handleClick = (e) => {
		e.preventDefault();
		ProductProvider.candidate();
	}

	render() {
		return (
			<div>
				<div className="form-group">
					<a href="#" className="col-md-6 control-label" onClick={(e) => this.handleClick(e)}>
						Přidat položky s předvyplněným popisem
					</a>
				</div>
				</div>);
	}
}