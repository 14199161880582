import React, { Component } from 'react';
import { AppStore } from './../../store/appstore';
import ProductDetail from './productDetail/productdetail.jsx';
import { ProductProvider } from './../../providers/productprovider.js';

export default class FeedItemList extends Component {

	state = {
		items: {},
		ids:[],
		page: 1,
		selecteditem: null,
		loadedIds: false,
		showOnlyUnpublished:false
	}

	componentDidMount() {

		AppStore.on('FeedItem.LoadList', (data) => {
			console.log('Product.AfterSearch in ProductList');
			this.setState({ items: data, loadedIds: false });
			this.loadids();
		});
		AppStore.on('FeedItem.LoadCandidate', (data) => {
			console.log('Product.AfterSearch in ProductList');
			this.setState({ items: data, loadedIds: false, showOnlyUnpublished: true });
			this.loadids();
		});
	}

	loadids = () => {
		ProductProvider.productIds((data) => {
			this.setState({ ids: data, loadedIds:true});
		});
	}

	handleCreate = (e, item) => {
		ProductProvider.loadProduct(item.catalogueNumber, (data) => {
			this.setState({ selectedItem: data });
		});
	}

	handleEdit = (e, item) => {
		ProductProvider.getProduct(item.catalogueNumber, (data) => {
			this.setState({ selectedItem: data });
		});
	}

	findProduct(id) {
		return this.state.ids.indexOf(id) > -1;
	}

	onSuccessSave = () => {
		this.setState({ selectedItem: null });
		alert('Produkt byl úspěsně uložen.');
		this.loadids();
	}  

    handleCloseDialog = (callback) => {
        this.setState({ selectedItem: null }, () => callback);
	}

	render() {
        var items = this.state.items.data;

		if (!items) {
			return (
				<div> Načítám</div >);
		}
		if (items.length === 0) {
			return (
				<div> Výběr je prázdný</div >);
		}


		return (
			<div>
				{this.state.selectedItem ?
					<ProductDetail item={this.state.selectedItem} onClose={() => this.handleCloseDialog()} onSuccessSave={() => this.onSuccessSave()} /> : null}
				<table className="table" >
					<thead>
						<tr>

							<th scope="col">Katalogové číslo</th>
							<th scope="col">Název</th>
							<th scope="col">Cena</th>
							<th scope="col">Dostupnost</th>
							<th scope="col">EAN</th>
							<th scope="col">&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{items.map((item) => {

							return (
								(this.state.showOnlyUnpublished && this.findProduct(item.catalogueNumber)) ?
									null :
									<tr>
										<td>{item.catalogueNumber}</td>
										<td>{item.name}</td>
										<td>{item.displayRoundedPrice}</td>
										<td>{item.displayStock}</td>
										<td>{item.barCode}</td>
										<td>
											{(this.state.loadedIds) ?
												(this.findProduct(item.catalogueNumber)) ?
													<button type="button" className="btn btn-info" onClick={(e) => this.handleEdit(e, item)}>Přidáno</button> :

													<button type="button" className="btn btn-primary" onClick={(e) => this.handleCreate(e, item)}>Přidat</button>
												: <span>Načítám stav..</span>
											}</td>
									</tr>
							);
						})}
					</tbody>
				</table>
			</div>);
	}
}