import { BaseProvider } from './baseprovider';
import { Constants } from './../store/constants';

export class UserProvider {
    static Login(data, callBack) {
		BaseProvider.sendToServerByPost(data, Constants.Url.UserLogin, (data) => {
			localStorage.setItem("authToken", data.token);
			window.location.reload();
            if (callBack) {
                callBack(data);
            }
        });
    }

    static GetUserDetail(callBack, callBackError) {
        BaseProvider.sendToServerByGet(Constants.Url.UserDetail, (data) => {
            if (callBack) {
                callBack(data);
            }
        }, (error) => {
                if (callBackError) {
                callBackError(error);
            }
        });
    }

    static Logout(callBack, callBackError) {
        BaseProvider.sendToServerByGet(Constants.Url.UserLogout, (data) => {
            if (callBack) {
                callBack(data);
            }
        }, (error) => {
                if (callBackError) {
                callBackError(error);
            }
        });
    }

}