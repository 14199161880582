import React, { Component } from 'react';
import CreditList from '../components/credit/creditlist.jsx';
import { LoadingButton } from '../components/common/loadings/index.jsx';
import CreditDetail from '../components/credit/creditDetail/creditdetail.jsx';

export default class Credits extends Component {

    state = {
        reload: false,
    }

    onClickShowDialog(e) {
        this.setState({ createNew: true });
    }

    onSuccessSave = () => {
        this.setState({ createNew: false },
            () => setTimeout(function () {
                alert('Zápočet byl úspěsně uložen.');
            }, 500));
    }  

    render() {
        const { createNew} = this.state;

        return (
            <section>
                <div className="row" >
                    <LoadingButton cls="btn btn-primary pull-right" text="Přidat zápočet" loading={createNew} onClick={(e) => this.onClickShowDialog(e)} />
                </div>
                {createNew ? <CreditDetail onSuccessSave={this.onSuccessSave} onClose={() => this.setState({ createNew: false })} isNew={true} /> : null}
                <CreditList />
            </section>
        );
    }
}