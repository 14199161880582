import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { formatDate, formatDatetime } from './../../../../../utils/dateformat.js'

const PromotionRow = props => (
    <tr>
        <td>
            <input type="number" defaultValue={props.promotion.price} className="form-control" required onChange={(e) => props.onChangeProperty(e.currentTarget.value, 'price', props.index)} />
        </td>
        <td>
            <DatePicker
                value={formatDate(props.promotion.from)}
                className={"form-control"}
                required
                onChange={(date) => props.onChangeProperty(formatDatetime(date), 'from', props.index)}
            />
        </td>
        <td>
            <DatePicker
                value={formatDate(props.promotion.to)}
                className={"form-control"}
                required
                onChange={(date) => props.onChangeProperty(formatDatetime(date), 'to', props.index)}
            />
        </td>
        <td>
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => props.onDelete(props.index)}>x</button>
        </td>
    </tr>
);

export default PromotionRow;