import React, { Component } from 'react';
import { Constants } from './../../store/constants.js';
import { CreditProvider } from '../../providers/creditprovider.js';
import CreditDetail from './creditDetail/creditdetail.jsx';
import PageBar from './../../components/common/paging/pageBar.jsx';
import { Loading } from './../../components/common/loadings/index.jsx';
import { formatDate, formatDatetime } from './../../utils/dateformat.js';

export default class CreditList extends Component {

    state = {
        items: [],
        pageIndex: 1,
        ordering: "title",
        selectedItem: null,
        pageCount: 1,
        isLoading: true
    }

    componentWillMount() {
        this.loadData();
    }

    loadOrder = (credit) => {
        let { items } = this.state;

        this.setState({ isLoading: true });

        CreditProvider.getCreditOrders(credit.id, (response) => {
            items.push({
                credit: credit,
                orders: response
            })
            this.setState({ items: items, isLoading: false });

        });
    }

    loadData() {
        const { pageIndex, ordering } = this.state;

        const pageModel = { pageIndex: pageIndex, ordering: ordering };

        CreditProvider.getCredits(pageModel, (response) => {

            this.setState({ isLoading: false });

            if (response.data) {
                response.data.forEach(credit => {
                    this.loadOrder(credit);
                });
            }
        });
    }

    onChangePage = (page) => {
        this.setState({ pageIndex: page, isLoading: true }, () => this.loadData());
    }

    handleCloseDialog = () => {
        this.setState({ selectedItem: null });
    }

    handleSendMall = (item) => {
        var cont = confirm('Opravdu chcete odeslat fakturu?');
        if (cont) {
            CreditProvider.sendCredit(item.credit.id, (response) => {
                alert('Zápočet byl úspěšně odeslán');
            }, (error) => {
                alert('Během odesílání zápočtu nastala chyba.');
            });
        }
    }

    handleEdit = (e, item) => {
        this.setState({ selectedItem: item });
    }

    onClickSort = (column) => {
        this.setState({ ordering: column }, this.loadData());
    }

    render() {
        const { items, selectedItem, pageCount, pageIndex, isLoading } = this.state;

        const pagingObject = {
            data: items,
            activePage: pageIndex,
            pageCount: pageCount,
        };

        return (
            <Loading isLoading={isLoading}>
                <div>
                    {selectedItem ? <CreditDetail item={selectedItem.credit} orders={selectedItem.orders} onDetailSendMall={this.handleSendMall} onClose={() => this.handleCloseDialog()} isNew={false} /> : null}
                    {items && items.length > 0 ? <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Vytvořeno</th>
                                <th scope="col">Od</th>
                                <th scope="col">Do</th>
                                <th scope="col">Počet objednávek</th>
                                <th scope="col">Celková cena</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => {
                                return (
                                    <tr key={item.credit.id}>
                                        <td>{formatDate(item.credit.created)}</td>
                                        <td>{formatDate(item.credit.from)}</td>
                                        <td>{formatDate(item.credit.to)}</td>
                                        <td>{item.orders.length}</td>
										<td><button type="button" className="btn btn-primary" onClick={(e) => this.handleEdit(e, item)}>Detail</button></td>
										<td><button type="button" className="btn btn-primary" onClick={(e) => window.location.href = Constants.Url.DownloadCredit + "?id=" + item.credit.id}>Stáhnout</button></td>
                                        <td><button type="button" className="btn btn-primary" onClick={(e) => this.handleSendMall(item)}>Odeslat na mall.sk</button></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table> : <p>Zápočty jsou prázdné</p>}
                    <PageBar data={pagingObject} onPageChanged={(page) => { this.onChangePage(page) }} />
                </div>
            </Loading>);
    }
}