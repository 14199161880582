import { BaseProvider } from './baseprovider';
import { Constants } from '../store/constants';
import { AppStore } from '../store/appstore';




export class DeliveryProvider {

	static getDelivery(id, callBack) {
		BaseProvider.sendToServerByGet(Constants.Url.Delivery + "?id=" + id, (data) => {
			if (callBack) {
				callBack(data);
			}
		});
	}
	static getNewDelivery(callBack) {
		BaseProvider.sendToServerByGet(Constants.Url.NewDelivery, (data) => {
			if (callBack) {
				callBack(data);
			}
		});
	}
	static getDeliveries(callback) {
		BaseProvider.sendToServerByGet(Constants.Url.Deliveries, (data) => {
			AppStore.emit('Deliveries.Update', data);
			if (callback) callback(data);
		});
	}

	static getTransports(callBack) {
		BaseProvider.sendToServerByGet(Constants.Url.Transporties, (data) => {
			if (callBack) {
				callBack(data);
			}
		});
	}

	static getStates(status,callback) {
		BaseProvider.sendToServerByGet(Constants.Url.OrderStates + "?state=" + status, (data) => {
			callback(data);
		});
	}

	static update(item, callBack, errorBack, proccessBack) {
		BaseProvider.sendToServerByPost(item, Constants.Url.DeliverySave, (data) => {
			if (callBack) {
				callBack(data);
			}
		}, (e) => {
			console.log(e);

			}, () => {
			if (proccessBack)
				proccessBack();
		});
	}
}