import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { AppStore } from '../../store/appstore.js';
import { OrderProvider } from '../../providers/orderprovider';
import { LoadingButton } from '../common/loadings/index.jsx';


export default class OrderDetail extends Component {
	static defaultProps = {
		tabs: [
			{
				id: 'basic',
				text: 'Základní údaje'
			},
			{
				id: 'items',
				text: 'Položky'
			},
			{
				id: 'address',
				text: 'Adresa'
			}
		]
	}

	state = {
		selectedtab: 'basic',
		states: [],
		updateLoad: false
	}



	componentWillMount() {
		this.loadStates(this.props.item.status);
		this.setState({ item: this.props.item, originalStatus: this.props.item.status });
	}

	componentDidMount() {

	}

	handleClose() {
		if (this.props.onClose)
			this.props.onClose();
	}

	handleConfirmState(e) {
		var that = this;
		e.preventDefault();
		OrderProvider.update(this.state.item,
			(data) => {
				that.setState({ originalStatus: this.state.item.status, updateLoad: false });
				AppStore.emit('Orders.refresh');
			},
			(error) => {
				alert(error);
				that.setState({ updateLoad: false });
			},	
		);
	}

	toggleBlock(tab) {

		this.setState({ selectedtab: tab });
	}


	loadStates(state) {
		OrderProvider.getStates(state, (data) => {

			this.setState({ states: data });
		});
	}

    handleChange(e, property, parent) {
		var item = this.state.item;
		if (parent) {
			var parItem = item[parent];
			parItem[property] = e.target.value;
		}
		else {
			item[property] = e.target.value;
		}
		this.setState({ item: item });
    }

    handleChangeDate = (date, property, parent) => {
        var item = this.state.item;

        if (parent) {
			var parItem = item[parent];
            parItem[property] = date;
		}
		else {
            item[property] = date;
		}
		this.setState({ item: item });
	}

	handleStateChange(e) {
		var item = this.state.item;
		item.status = e.target.value;
		this.setState({ item: item });
	}

	render() {
        var item = this.state.item;

        return (
			<div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title"><a href={item.url}>{item.title}</a></h5>
							<button type="button" className="close" data-dismiss="modal" onClick={() => this.handleClose()} aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="container-fluid">
								<form className=".form-horizontal">
									<ul className="nav nav-tabs">
										{this.props.tabs.map((tab) => {
											return <li className={(this.state.selectedtab == tab.id) ? 'active' : ''} onClick={() => this.toggleBlock(tab.id)} ><a href="#">{tab.text}</a></li>;
										})}
									</ul>


									{(this.state.selectedtab === 'basic') ?
										<div className="tab-content">
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Objednávka č.</label>
												<div className="col-sm-8">
													<input type="text" readOnly="true" className="form-control" required value={item.id} /></div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Mall č.</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.purchaseId} readOnly="true" /></div>
											</div>

											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Idea č.</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.displayExternalOrderId} readOnly="true" /></div>
											</div>

											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Cena k platbě</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.displayCod} readOnly="true" /></div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Cena za dopravu</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.displayDeliveryPrice} readOnly="true" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Cena za dobírku</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.displayCodPrice} readOnly="true" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Doprava</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.deliveryMethod} readOnly="true" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Plánované doručení</label>
                                                <div className="col-sm-8">
                                                    <DatePicker
                                                        selected={new Date(item.shipDate)}
                                                        onChange={(e) => this.handleChangeDate(e, "shipDate")}
                                                        className={"form-control"}
                                                        readOnly
                                                    />
												</div>
                                            </div>
                                            <div className="form-group row">
												<label className="col-sm-4 col-form-label">Datum doručení</label>
                                                <div className="col-sm-8">
                                                    <DatePicker
                                                        selected={new Date(item.deliveryDate)}
                                                        className={"form-control"}
                                                        onChange={(e) => this.handleChangeDate(e, "deliveryDate")}
                                                        readOnly={!(item.status == 'delivered')}
                                                        required={(item.status == 'delivered')}
                                                    />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Potvrzeno</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.displayConfirmed} readOnly="true" />
												</div>
											</div>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label" >Tracking number</label>
												<div className="col-sm-8">
                                                    <input type="text" className="form-control" value={item.trackingNumber} required={(item.status == 'shipped')} readOnly={!(item.status == 'shipped')}onChange={(e) => this.handleChange(e,'trackingNumber')}
													/>
												</div>
											</div>


											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Stav</label>
												<div className="col-sm-6">
													<select className="form-control" required onChange={(e) => this.handleStateChange(e)}>
														{(this.state.states) ?
															this.state.states.map((state) => {
																return (
																	<option value={state.key} selected={state.key === item.status}>{state.value}</option>
																);
															})
															: null}
													</select>
												</div>
												{(item.status != this.state.originalStatus) ?
													<div className="col-sm-2">
														<button type='button' onClick={(e) => this.handleConfirmState(e)} disable={this.state.updateLoad} >Změnit</button>
													</div> : null}
											</div>

										</div> : null}



									{(this.state.selectedtab === 'items') ?
										<div className="tab-content">
											<table className="table">
												<thead>
													<tr>
														<th>Kód</th>
														<th>Název</th>
														<th>Počet</th>
														<th>Cena vč. DPH</th>
														<th>DPH</th>
													</tr>
												</thead>
												<tbody>
													{item.items.map((subitem) => {
														return (
															<tr>
																<td>{subitem.id}</td>
																<td>{subitem.title}</td>
																<td>{subitem.quantity}</td>
																<td>{subitem.displayPrice}</td>
																<td>{subitem.vat}</td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</div> : null}


									{(this.state.selectedtab === 'address') ?
										<div className="tab-content">
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Jméno</label>
												<div className="col-sm-8">
													<input className="form-control" type="text" value={item.address.name} readOnly="true" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Adresa</label>
												<div className="col-sm-8">
													<input className="form-control" type="text" value={item.address.street} readOnly="true" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Město</label>
												<div className="col-sm-8">
													<input className="form-control" type="text" value={item.address.city} readOnly="true" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">PSČ</label>
												<div className="col-sm-8">
													<input className="form-control" type="text" value={item.address.zip} readOnly="true" />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Země</label>
												<div className="col-sm-8">
													<input className="form-control" type="text" value={item.address.country} readOnly="true" />
												</div>
											</div>
										</div> : null}
								</form>
							</div>
						</div>

						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.handleClose()}>Close</button>
						</div>
					</div>
				</div>
			</div>);
	}
}