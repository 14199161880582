import React, { Component } from 'react';
import ActivityList from '../components/activity/activityList.jsx';

const Activities = props => (
    <section>
        <ActivityList />
    </section>
    );

export default Activities;
