import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

export default class InputFormattedText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
        };
    }

    componentWillMount() {
        if (this.props.defaultValue && this.props.defaultValue !== undefined) {
            const content = stateFromHTML(this.props.defaultValue);

            this.setState({
                editorState: EditorState.createWithContent(content)
            });
        }
    }

    onEditorStateChange = (editorState) => {

       const htmlText = stateToHTML(editorState.getCurrentContent());
        this.props.onChange(htmlText);

        this.setState({
            editorState,
        });
    }

    handleBeforeInput = (chars) => {
        const currentContent = this.state.editorState.getCurrentContent();

        let currentContentLength = 0;
        if (currentContent)
            currentContentLength = currentContent.getPlainText('').replace(/\n|\r/g, "").length;

        const { maxLength } = this.props;

        if (maxLength == undefined)
            return 'handled';

        if (currentContentLength + 1 > maxLength) {
            return 'handled';
        } else {
            return 'not-handled';
        }
    }

    render() {
        const { editorState } = this.state;
        const { label } = this.props;

        return (
            <React.Fragment>
                <label>{label}</label>
                <div className="form-group row">
                    <div className="col-sm-16">
                        <Editor
                            editorState={editorState}
                            editorClassName="form-control"
                            onEditorStateChange={this.onEditorStateChange}
                            handleBeforeInput={this.handleBeforeInput}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}