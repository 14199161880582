import React, { Component } from 'react';

import { ProductProvider } from './../../../providers/productprovider.js';
import {
    LabelsTab, PromotionsTab, EnhancedTab,
    ParametersTab, DimensionsTab, BasicTab, DescriptionTab
} from './tabs/index.jsx';

export default class ProductDetail extends Component {

	static defaultProps = {
		tabs: [
			{
				id: 'basic',
				text: 'Základní údaje'
			},
			{
				id: 'description',
				text: 'Popisy'
			},
			{
				id: 'dimensions',
				text: 'Rozměry balení'
			},
			{
				id: 'parameters',
				text: 'Kategorie a parametry'
			},
			{
				id: 'enhanced',
				text: 'Rozšířené'
			},
			{
				id: 'labels',
				text: 'Štítky'
			},
			{
				id: 'promotions',
				text: 'Cenové akce'
			}
		]
	}

	state = {
		selectedtab: 'basic',
		categoryDetail:null,
		changed: false,
		menuitems:[]

	}

	componentWillMount() {
		this.setState({
			item: this.props.item,
			changed: (this.props.item.status == null)
		});
	}

	componentDidMount() {

		this.loadMenuItems();
		this.loadProperties(this.state.item.menuItemId);
	}

	handleClose() {
		var cont = true;
		if (this.state.changed) {
			cont = confirm('Opravdu chcete dialog zavřít bez uložení?');
		}
		if (cont) {
			if (this.props.onClose)
				this.props.onClose();
		}
	}

	handleReload(e) {
		if (confirm('Opravdu přepsat výrobek?'))
			ProductProvider.reloadProduct(this.state.item.id, (data) => {
				this.setState({ item: data, changed: true });
			});
		e.preventDefault();
	}

	handleUpdatePrice(e) {
		if (confirm('Opravdu zaktualizovat cenu podle feedu?'))
			ProductProvider.updatePrice(this.state.item.id, (data) => {
				ProductProvider.getProduct(this.state.item.id, (data) => {
					this.setState({ item: data, changed: false});
				});
			});
		e.preventDefault();
	}

	handleUpdateDescriptions(e) {
		if (confirm('Opravdu zaktualizovat popisy podle feedu?'))
			ProductProvider.updateDescriptions(false,this.state.item.id, (data) => {
				ProductProvider.getProduct(this.state.item.id, (data) => {
					this.setState({ item: data, changed: false });
				});
			});
		e.preventDefault();
	}

	handleActivateOnMall(e) {
		ProductProvider.activateProduct(this.state.item.id, (data) => {
			this.setState({ item: data });
		});
	}

	handleUpdate(e) {
		e.preventDefault();
        const { item } = this.state;

        ProductProvider.saveProduct(item,
			(data) => {
				alert('Produkt byl úspěsně uložen.');
                if (this.props.onSuccessSave)
                    this.props.onSuccessSave();                 
            },
            (error) => {
                alert(error.exceptionMessage);
            }
        );
    }

	loadMenuItems() {
		ProductProvider.getMenuItems((data) => {

			this.setState({ menuItems: data });
		});
	}

	loadProperties(categoryId) {
		var catid = categoryId;
		if (!categoryId) catid = '';
		var item = this.state.item;
		if (catid) {
			ProductProvider.getMenuItemDetail(catid, (data) => {

				
				item.menuItemId = catid;
				item.categoryId = data.sapId;
				var keyNames = Object.keys(item.items);

				data.categoryConditions.forEach((par) => {
					if (par.options && par.options.length > 0) {
						var foundKey = keyNames.find((k) => {
							return par.parameter.toLowerCase() == k.toLowerCase();
						});
						var idx = -1;
						var foundValue = (foundKey) ? item.items[foundKey] : null;
						if (foundValue) {
							idx = par.options.findIndex((opt)=>opt.value==foundValue);
                        }

						if (idx <0) {
							item.items[foundKey] = [par.options[0].value];
                        }
					}
					else {
						item.items[this.loverFirstLetter(par.parameter)] = [par.value];
					}
                })

				keyNames.forEach((par) => {
						var found = data.otherParameters.find((parameter) => {
							return parameter.value.toLowerCase() == par.toLowerCase();
						});
						if (!found)
							delete item.items[par];
				
				})
				this.setState({ item: item, categoryDetail: data });
			});
		}
		else {
			var keyNames = Object.keys(item.items);
			keyNames.forEach((par) => {
				delete item.items[par];
			})}
	}
	loverFirstLetter = (string) => {
		return string.charAt(0).toLowerCase() + string.slice(1);
	}


	handleCategoryChange = (e) => {

		var item = this.state.item;
		item.categoryId = e.target.value;

		this.loadProperties(item.categoryId);
		this.setState({ item: item, changed: true });
	}

	toggleBlock(e,tab) {
		e.preventDefault();
		this.setState({ selectedtab: tab });
	}

	handleChange = (e, property, parent) => {

		var item = this.state.item;

		if (parent) {
			var parItem = item[parent];
			parItem[property] = e.target.value;
		}
		else {
			item[property] = e.target.value;
		}
		this.setState({ item: item, changed: true });
	}

	handleChangeValue = (value, property, parent) => {

		var item = this.state.item;

		if (parent) {
			var parItem = item[parent];
			parItem[property] = value;
		}
		else {
			item[property] = value;
		}
		this.setState({ item: item, changed: true });
	}

	handleChangeParameter = (e, property) => {
		var item = this.state.item;
		this.setParameterValue(item, property, e.target.value);
		this.setState({ item: item, changed: true });
	}
	handleChangeCondition = (e, property) => {
		var item = this.state.item;
		this.setConditionValue(item, property, e.target.value);
		this.setState({ item: item, changed: true });
	}
	loverFirstLetter(string) {
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	setConditionValue(item, code, value) {
		if (value) {
			item.items[code] = value.split();
		}
		else
			if (item.items[code])
				delete item.items[code];
	}

	renderTab() {
		const { parameters, categories, item, selectedtab,menuItems } = this.state;

		switch (selectedtab) {
			case 'basic':
				return <BasicTab
					onChange={this.handleChange}
					onCategoryChange={this.handleCategoryChange}
					categories={categories}
					item={item}
				/>
				break;

			case 'description':
				return <DescriptionTab item={item} onChange={this.handleChangeValue} />
				break;

			case 'dimensions':
				return <DimensionsTab item={item} onChange={this.handleChange} />
				break;

			case 'parameters':
				return <ParametersTab categoryDetail={this.state.categoryDetail} parameters={parameters} menuItems={menuItems} item={item} onChangeCondition={this.handleChangeCondition}  onChange={this.handleChangeParameter} onCategoryChange={(e) => this.handleCategoryChange(e)} />
				break;

			case 'enhanced':
				return <EnhancedTab item={item} onChange={this.handleChange} />
				break;

			case 'promotions':
				return <PromotionsTab selectedPromotions={item ? item.promotions : []} onChange={this.handleChangeValue} />
				break;

			case 'labels':
				return <LabelsTab selectedLabels={item ? item.labels : []} onChangeLabel={this.handleChangeValue} />
				break;
		}
	}

	render() {
		const { item, selectedtab } = this.state;
		const { tabs } = this.props;

		return (
			<div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title"><a href={item.url} target="blk">{item.title}</a></h5>
							<button type="button" className="close" data-dismiss="modal" onClick={() => this.handleClose()} aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						 <form className=".form-horizontal" onSubmit={(e) => this.handleUpdate(e)}>
						<div className="modal-body">
							<div className="container-fluid">
								
									<ul className="nav nav-tabs" >
										{tabs.map((tab) => {
											return <li key={tab.id} className={(selectedtab == tab.id) ? 'active' : ''} onClick={(e) => this.toggleBlock(e,tab.id)} ><a href="#">{tab.text}</a></li>;
										})}
									</ul>
									<div style={{ paddingBottom: '20px' }}></div>
									{this.renderTab()}
							
							</div>
						</div>

						<div className="modal-footer">

							<button type="submit" className={(this.state.changed) ? 'btn btn-primary' : 'btn btn-primary disabled'}>Uložit</button>

							{(item.stage != 'live') ?
								<button type="button" className="btn btn-primary disabled" onClick={(e) => this.handleActivateOnMall(e)}>Activate on mall.sk</button>
									: null}

							<button type="button" className="btn btn-primary" onClick={(e) => this.handleUpdatePrice(e)}>Aktualizovat cenu</button>							
							<button type="button" className="btn btn-primary" onClick={(e) => this.handleUpdateDescriptions(e)}>Aktualizovat popisy</button>
							<button type="button" className="btn btn-primary" onClick={(e) => this.handleReload(e)}>Aktualizovat z feedu</button>
							<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.handleClose()}>Close</button>
						</div>
						</form>
					</div>
				</div>
			</div>);
	}
}