import React, { Component } from 'react';

import { ProductProvider } from './../../../../providers/productprovider.js';
import { Loading } from './../../../common/loadings/index.jsx';
import LabelsTable from '../tables/labelsTable.jsx';

export default class LabelsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    loadLabels() {
        this.setState({ isLoading: true });

        ProductProvider.getLabels((data) => {
            this.setState({
                labels: data,
                isLoading: false,
            });
        });
    }

    componentDidMount() {
        this.loadLabels();
    }

    render() {
        const { isLoading, labels } = this.state;
        const { selectedLabels, onChangeLabel } = this.props;

        return (
            <Loading isLoading={isLoading}>
                <LabelsTable labels={labels} selectedLabels={selectedLabels} onChange={onChangeLabel} /> 
            </Loading> 
        );
    }
}