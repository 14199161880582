import { BaseProvider } from './baseprovider';
import { Constants } from './../store/constants';

export class ActivityProvider {

    static getActivities(pageRequest, callBack) {
        BaseProvider.sendToServerByPost(pageRequest, Constants.Url.Activities, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }
}