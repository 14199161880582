import React, { Component } from 'react';
import { ProductProvider } from '../../providers/productprovider.js';

export default class ProductSearchBox extends Component {

	state = {
		searchValue: ''
	}
	handleClick = (e) => {
		ProductProvider.search(this.state.searchValue);
	}

	render() {
		return (
			<div>
				<div className="form-group">
					<div className="col-md-2 control-label">
						Zadejte katalogové číslo:
				</div>
					<div className="col-md-10 input-group">
						<input type="text" className="form-control" value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} />
						<button type="button" className="btn btn-primary" onClick={(e) => this.handleClick(e)} >Najít</button>

					</div>
				</div>
			</div>);
	}
}