import { BaseProvider } from './baseprovider';
import { Constants } from './../store/constants';
import { AppStore } from './../store/appstore';

export class CreditProvider {


    static getCredits(pageRequest, callBack) {
        BaseProvider.sendToServerByPost(pageRequest, Constants.Url.Credits, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

    static getCreditOrders(creditId, callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.GetCreditOrders + "?CreditId=" + creditId, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }
    
    static getCredit(creditId, callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.GetCredit + "?Id=" + creditId, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

    static createCredit(credit, callBack, errorCallBack) {
        BaseProvider.sendToServerByPost(credit, Constants.Url.CreateCredit, (data) => {
            if (callBack) {
                callBack(data);
            }
        }, (error) => {
                if (errorCallBack) {
                    errorCallBack(error);
            }
        });
    }

    static sendCredit(creditId, callBack, errorCallBack) {
        BaseProvider.sendToServerByGet(Constants.Url.SendCredit + "?CreditId=" + creditId, (data) => {
            if (callBack) {
                callBack(data);
            }
        }, (error) => {
            if (errorCallBack) {
                errorCallBack(error);
            }
        });
    }

    static prepareCredit(callBack) {
        BaseProvider.sendToServerByGet(Constants.Url.PrepareCredit, (data) => {
            if (callBack) {
                callBack(data);
            }
        });
    }

}