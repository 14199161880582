import React, { Component } from 'react';

import { AppStore } from '../../store/appstore';
import { DeliveryProvider } from '../../providers/deliveryprovider.js';
import DeliveryDetail from './deliverydetail.jsx';
import PageBar from './../../components/common/paging/pageBar.jsx';
import { Loading } from './../../components/common/loadings/index.jsx';

export default class DeliveryList extends Component {

    state = {
        items: {},
        pageIndex: 1,
        ordering: "title",
        selectedItem: null,
        pageCount: 1,
        isLoading: true
    }

    componentWillMount() {
        this.loadData();
    }

    loadData() {
        const { pageIndex, ordering } = this.state;

        const pageModel = { pageIndex: pageIndex, ordering: ordering };

        DeliveryProvider.getDeliveries(pageModel, (response) => {
            this.setState({
                items: response.data,
                pageCount: response.pageCount,
                isLoading: false
            });
        });
    }

    componentDidMount() {
        AppStore.on('Deliveries.Update', (response) => { // todo paging model
            this.setState({
                items: response.data,
                pageCount: response.pageCount,
                isLoading: false
            });
        });
    }

    onChangePage = (page) => {
        this.setState({ pageIndex: page, isLoading: true }, () => this.loadData());
    }

    handleEdit = (e, item) => {
        DeliveryProvider.getDelivery(item.code, (data) => {
            this.setState({ selectedItem: data });
        });


    }

    handleCloseDialog = () => {
        this.setState({ selectedItem: null });
    }

    onClickSort = (column) => {
        this.setState({ ordering: column }, this.loadData());
    }

    render() {
        const { items, selectedItem, pageCount, pageIndex, isLoading } = this.state;

        const pagingObject = {
            data: items,
            activePage: pageIndex,
            pageCount: pageCount,
        };

        if (!items.length) {
            return (<div> Výběr je prázdný</div >);
        }

        return (
            <Loading isloading={isLoading}>
                <div>
                    {this.state.selectedItem ? <DeliveryDetail item={selectedItem} onClose={() => this.handleCloseDialog()} isNew="false" /> : null}
                    <table className="table" >
                        <thead>
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col"><a className="font-weight-bold" onClick={() => this.onClickSort('title')}>Název</a></th>
                                <th scope="col">Cena</th>
                                <th scope="col">Dobírka</th>
                                <th scope="col">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => {
                                return (
                                    <tr>
                                        <td>{item.code}</td>
                                        <td>{item.title}</td>
                                        <td>{item.price}</td>
                                        <td>{item.codPrice}</td>
                                        <td><button type="button" className="btn btn-primary" onClick={(e) => this.handleEdit(e, item)}>Detail</button></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <PageBar data={pagingObject} onPageChanged={(page) => { this.onChangePage(page) }} />
                </div>
            </Loading>);
    }
}