import React from 'react';
import Loader from 'react-loader-spinner'

const Loading = props => (
    <div>
            {props.isLoading ?
            <Loader type="ThreeDots" color="blue" height={80} width={80} /> : props.children}
        </div>
);

export default Loading;