import React from 'react';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatDate, formatDatetime } from './../../../../utils/dateformat.js';

const BasicTab = props => (
    <div className="tab-content">
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Od</label>
            <div className="col-sm-8">
                <DatePicker
                    readOnly={!props.isNew}
                    value={props.item ? formatDate(props.item.from) : null}
                    className={"form-control"}
                    onChange={(date) => props.onChange(formatDatetime(date), 'from')}
                />
            </div>
        </div>
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Do</label>
            <div className="col-sm-8">
                <DatePicker
                    readOnly={!props.isNew}
                    value={props.item ? formatDate(props.item.to) : null}
                    className={"form-control"}
                    onChange={(date) => props.onChange(formatDatetime(date), 'to')}
                />
            </div>
        </div>
        {!props.isNew ?
            <div className="form-group row">
                <label className="col-sm-4 col-form-label">Počet objednávek</label>
                <div className="col-sm-8">
                    <input type="number" className="form-control" value={props.orders.length} readOnly />
                </div>
            </div> : null}
    </div>
);

export default BasicTab;