import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Products from '../pages/Products.jsx';
import Orders from '../pages/Orders.jsx';
import Activities from '../pages/Activities.jsx';
import Deliveries from '../pages/Deliveries.jsx';
import Credits from '../pages/Credits.jsx';
import AuthRoute from '../components/common/routes/authRoute.jsx';

export default class HomeRouter extends Component {
	state = {
		loading: false
	}



	componentWillMount() {

	
	}

	render() {
		if (this.state.loading)
			return null;

		return (
			<div>
                <AuthRoute path="/" exact component={Products}/>
				<AuthRoute path="/Home/Orders" component={Orders} />
				<AuthRoute path="/Home/Deliveries" component={Deliveries} />
				<AuthRoute path="/Home/Credits" component={Credits} />
				<AuthRoute path="/Home/Activities" component={Activities} />
			</div>
			
		);
	}
}

var element = document.getElementById("app");
if (element) {
	ReactDOM.render(
		<BrowserRouter>
			<HomeRouter />
		</BrowserRouter>, element);
}