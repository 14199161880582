import React, { Component } from 'react';
import PromotionRow from './row/promotionRow.jsx';
import moment from 'moment';

export default class PromotionsTable extends Component {
    constructor(props) {
        super(props);
    }

    onChangeProperty = (value, property, index) => {
        let { selectedPromotions } = this.props;
        let item = selectedPromotions[index];

        item[property] = value;

        this.props.onChange(selectedPromotions, 'promotions');
    }

    handleAddRow = () => {
        let { selectedPromotions } = this.props;

        const date = moment().format('YYYY-MM-DD');

        selectedPromotions.push({ from: date, to: date, price: 0});

        this.props.onChange(selectedPromotions, 'promotions');

    }

    handleDeleteRow = (index) => {
        let { selectedPromotions } = this.props;

        selectedPromotions.splice(index, 1);

        this.props.onChange(selectedPromotions, 'promotions');
    }

    render() {
        const { selectedPromotions } = this.props;

        return (
            <React.Fragment>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.handleAddRow()}>Přidat</button>
                {selectedPromotions && selectedPromotions.length > 0 ? <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Cena</th>
                            <th scope="col">Dostupnost od</th>
                            <th scope="col">Dostupnost do</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedPromotions && selectedPromotions.map((promotion, index) => {
                            return (
                                <PromotionRow readOnly onDelete={this.handleDeleteRow} promotion={promotion} index={index} onChangeProperty={this.onChangeProperty} />
                            );
                        })}
                    </tbody>
                </table>: <p>Cenové akce jsou prázdné</p>}
            </React.Fragment>
        );
    }
}