import Freezer from 'freezer-js';

export var AppStore = new Freezer({

	masterLoading: false
});

AppStore.on('FeedItem.LoadList', function (data) {
	console.log('FeedItem.LoadList');

});

AppStore.on('FeedItem.LoadCandidate', (data) => {
	console.log('Product.LoadCandidate in ProductList');
});