import * as React from 'react';
import { Route } from "react-router-dom";
import LoginForm from '../login/loginForm.jsx';
import AdminLayout from '../layouts/adminLayout.jsx';

class AuthRoute extends React.Component {

    renderContent = (props) => {
        const { renderComponent } = this.props;

        if (renderComponent) {
            return renderComponent();
        }

        const Component = this.props.component;

        return <Component {...props} />
    }

    render() {

        const { renderComponent, ...rest } = this.props;
        const bearerToken = localStorage.getItem("authToken");

        return <Route {...rest} component={props => (
            <React.Fragment>
                {bearerToken ? <AdminLayout>{this.renderContent(props)}</AdminLayout> : <LoginForm />}
            </React.Fragment>
        )} />;
    }
}

export default AuthRoute;