import React, { Component,Fragment } from 'react';

import { Loading } from './../../../common/loadings/index.jsx';
import { ProductProvider } from './../../../../providers/productprovider.js';

export default class ParametersTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }


    componentDidMount() {
    }


   

    loverFirstLetter = (string) => {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    getParameterValue = (item, code) => {
        var lst = null;
        for (var name in item.items) {
            if (name.toLocaleLowerCase() == code.toLocaleLowerCase()) {
                lst = item.items[name];
			}
        }



        if (lst) {
            return lst.join();
        }
        else
            return null;
    }

    render() {
        const { isLoading, parametersWithValues } = this.state;
        const { onChange, onChangeCondition, item, parameters, menuItems } = this.props;

        return (
            <Loading isLoading={isLoading}>
                <div className="tab-content tab-parameters" style={{overflowX:'hidden'}}>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Kategorie</label>
                        <div className="col-sm-8">
                            <select className="form-control" onChange={(e) => this.props.onCategoryChange(e)} style={{maxWidth:'100%'}}>
                                <option value="" >Vyberte</option>
                                {(menuItems) ?
                                    menuItems.map((category) => {
                                        return (
                                            <option key={category.menuItemId} value={category.menuItemId} selected={category.menuItemId === item.menuItemId}>{category.parentPath +"/" + category.name}</option>
                                        );
                                    })
                                    : null}
                            </select>
                        </div>
                    </div>
                    {this.props.categoryDetail ?
                        <Fragment>
                            {(this.props.categoryDetail.categoryConditions.length > 0) ?
                                <div class="form-group row" style={{ backgroundColor: 'ghostwhite' }}>
                                    <label className="col-sm-8 col-form-label">Povinné parametry</label></div> : null}


                            {this.props.categoryDetail.categoryConditions.map((value) => {
                                let defaultValue = this.getParameterValue(item, value.parameter);
                                return <div className="form-group row" key={value.parameter}>
                                    <label className="col-sm-4 col-form-label">{value.parameter}</label>
                                    <div className="col-sm-8">
                                        {value.options && value.options.length > 0 ?
                                            <select className="form-control" onChange={(e) => onChangeCondition(e, value.parameter)}>
                                                {value.options.map((val) => {
                                                    return <option value={val.value} selected={defaultValue == val.value}>{val.text}</option>
                                                })}
                                            </select>
                                            : <input className="form-control" disabled readOnly type="text" value={defaultValue} onChange={(e) => onChange(e, value.parameter.key)} />}
                                    </div>
                                </div>
                            })}</Fragment> : null}
                    {this.props.categoryDetail ?
                        <Fragment>
                            {(this.props.categoryDetail.otherParameters.length > 0) ?
                                <div class="form-group row" style={{ backgroundColor: 'ghostwhite' }}>
                                    <label className="col-sm-8 col-form-label">Nepovinné parametry</label></div> : null}
                            {this.props.categoryDetail.otherParameters.map((value) => {
                                let defaultValue = this.getParameterValue(item, value.value);

                                return <div className="form-group row" key={value.value}>
                                    <label className="col-sm-4 col-form-label">{value.text}</label>
                                    <div className="col-sm-8">
                                        {value.options && value.options.length > 0 ?
                                            <select className="form-control" onChange={(e) => onChange(e, value.value)}>
                                                <option value="" >Vyberte</option>
                                                {value.options.map((val) => {
                                                    return <option value={val.value} selected={defaultValue == val.value}>{val.text}</option>
                                                })}
                                            </select>
                                            : <input className="form-control" type="text" value={defaultValue} onChange={(e) => onChange(e, value.value)} />}
                                    </div>
                                </div>
                            })}</Fragment> : null}
                </div>
            </Loading>);
    }
}

