import React, { Component } from 'react';
import OrderList from '../components/order/orderlist.jsx';
import { LoadingButton } from '../components/common/loadings/index.jsx';
import { OrderProvider } from '../providers/orderprovider.js';

export default class Orders extends Component {

	state = {
		updateUnConfirmed: false,
		updateList: false,
		checkOrders:false
	}

	handleUpdateConfirmed(e) {
		

		OrderProvider.updateUnConfirmed((data) => {
			
			alert("Nové objednávky načteny");
			OrderProvider.getOrders();
			this.setState({ updateUnConfirmed: false });
		},
			(err) => {
				alert(err);
				this.setState({ updateUnConfirmed: false });
			}
		);
		this.setState({ updateUnConfirmed: true });
	}

	handleCheckOrders(e) {
		
		OrderProvider.checkOrders((data) => {
			this.setState({ checkOrders: false });
			alert("Stav objednávek zkontrolován");
			OrderProvider.getOrders();
		},
			(err) => {
				alert(err);
				this.setState({ checkOrders: false });
			}
		);
		this.setState({ checkOrders: true });
	}



	render() {
		return (
			<section>
				<div className="row">
					<LoadingButton cls="btn btn-primary pull-right" text="Zkontrolovat doručení objednávky" loading={this.state.checkOrders} onClick={(e) => this.handleCheckOrders(e)} />

					<LoadingButton cls="btn btn-primary pull-right" text="Aktualizovat nepotvrzené" loading={this.state.updateUnConfirmed} onClick={(e) => this.handleUpdateConfirmed(e)} />
			
				</div>
				<OrderList location={this.props.location}/>
			</section>
		);
	}
}