import React, { Component } from 'react';

export default class PageBar extends Component {

    state = {
        activePage: 1
    }

    handleClick = (page) => {
        if (this.state.activePage != page) {

            this.setState({
                activePage: page
            });
            this.props.onPageChanged(page);
        }
    }

	componentWillMount() {
		var pindex = 1;
		if (this.props.data && this.props.data.activePage)
			pindex = this.props.data.activePage;
		//this.handleClick(pindex, true);
		this.setState({
			activePage: pindex
		});
    }
   
    handlePrevClick = () => {
        if (this.state.activePage > 1) {
            this.handleClick(this.state.activePage - 1, true);
        }
    }

    handleNextClick = () =>  {
        if (this.state.activePage < this.props.data.pageCount)
            this.handleClick(this.state.activePage + 1, true);
    }

    render() {
        const { activePage } = this.state;
        const { data } = this.props;

        if (!data.data || data.data.length == 0) {
            return null;
        }

        let items = [];

        for (let page = 1; page <= data.pageCount; page++) {
            let className = "page-item";
            const active = <span className="sr-only">(current)</span>;

            if (page == activePage) {
                className += " active";
            }

            items.push(
                <li className={className}>
                    <a className="page-link" key={page} href="#" onClick={() => this.handleClick(page)}>
                        {page}
                        {page == activePage ? active : null}
                    </a>
                </li>
            );
        }

        return (
            <div>
            {data && data.pageCount > 1 ?
                    <ul className="pagination">
                        <li className="page-item disabled">
                            <a className="page-link" href="#" tabIndex="-1" onClick={this.handlePrevClick}>Previous</a>
                        </li>
                        {items}
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={this.handleNextClick}>Next</a>
                        </li>
                    </ul> : null}
            </div>
        );
    }

}