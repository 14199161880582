const urlPrefix = '/api/';

export const Constants = {
	Url: {
		Search: urlPrefix + 'feeditem/search',
		Candidate: urlPrefix + 'feeditem/candidate',
		SearchByEanList: urlPrefix + 'feeditem/searchbyeanlist',
		SearchByCodes: urlPrefix + 'feeditem/searchbycodes',
		Labels: urlPrefix + 'label/list',
		Categories: urlPrefix + 'category/list',
		MenuItems: urlPrefix + 'category/menuitems',
		MenuItemDetail: urlPrefix + 'category/menuItemDetail',
		Transporties: urlPrefix + 'delivery/transports',
        ParametersValues: urlPrefix + 'category/getparametervalues',
		Parameters: urlPrefix + 'category/getparameters',
		Products: urlPrefix + 'product/published',
		ProductIds: urlPrefix + 'product/publishedids',
        Activities: urlPrefix + 'activity/list',
        Credits: urlPrefix + 'credit/list',
        PrepareCredit: urlPrefix + 'credit/prepare',
        GetCreditOrders: urlPrefix + 'credit/orders',
        UserDetail: urlPrefix + 'user/get',
        UserLogin: urlPrefix + 'user/login',
        UserLogout: urlPrefix + 'user/logout',
        CreateCredit: urlPrefix + 'credit/create',
        GetCredit: urlPrefix + 'credit/get',
		SendCredit: urlPrefix + 'credit/send',
		DownloadCredit: urlPrefix + 'credit/credit',
		ActivateProduct: urlPrefix + 'product/activate',
		GetProduct: urlPrefix + 'product/get',
		SaveProduct: urlPrefix + 'product/save',
		UpdateStore: urlPrefix + 'product/updatestore',
		UpdateRecomendedAll: urlPrefix + 'product/UpdateRecomendedAll',
		LoadProduct: urlPrefix + 'feeditem/load',
		ReloadProduct: urlPrefix + 'feeditem/reload',
		UpdatePrice: urlPrefix + 'product/updateproductprice',
		UpdateDescriptions: urlPrefix +  'product/updatedescriptions',
		Orders: urlPrefix + 'order/list',
		Deliveries: urlPrefix + 'delivery/list',
		Order: urlPrefix + 'order/get',
		Delivery: urlPrefix + 'delivery/get',
		DeliverySave: urlPrefix + 'delivery/save',
		NewDelivery: urlPrefix + 'delivery/getnew',
		OrderStates: urlPrefix + 'order/getstates',
		OrderUpdate: urlPrefix + 'order/update',
		CheckOrders: urlPrefix + 'delivery/checkorders', 
		UpdateUnConfirmed: urlPrefix + 'order/processUnconfirmed' 

	}
}