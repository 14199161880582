import React, { Component } from 'react';

import ProductDetail from './productDetail/productdetail.jsx';
import { ProductProvider } from '../../providers/productprovider.js';
import PageBar from './../../components/common/paging/pageBar.jsx';
import { Loading } from './../../components/common/loadings/index.jsx';


export default class MallProductList extends Component {

    state = {
        items: {},
        pageIndex: 1,
        ordering: "title",
        selectedItem: null,
        pageCount: 1,
        isLoading: true
    }

    componentWillMount() {
        this.loadData();
    }

    loadData() {
        const { pageIndex, ordering } = this.state;

        const pageModel = { pageIndex: pageIndex, ordering: ordering };

        ProductProvider.getProducts(pageModel, (response) => {
            this.setState({
                items: response.data,
                pageCount: response.pageCount,
                isLoading: false
            });
        });
    }

    handleEdit = (e, item) => {
        ProductProvider.getProduct(item.id, (data) => {
            this.setState({ selectedItem: data });
        });
    }

    handleCloseDialog = () => {
        this.setState({ selectedItem: null });
    }

    onSuccessSave = () => {
        this.setState({ selectedItem: null });
      /*      () => setTimeout(function () {
              

            }, 500));*/
       // this.loadData();
    }

    handleActivate = (e, id) => {
		ProductProvider.activateProduct(id, () => {
			ProductProvider.getProducts({ PageIndex: this.state.pageIndex }, (data) => {

                this.setState({ items: data.data });
            });
        });
    }

    onChangePage = (page) => {
        this.setState({ pageIndex: page, isLoading: true }, () => this.loadData());
    }

    onClickSort = (column) => {
        this.setState({ ordering: column }, this.loadData());
    }

    render() {
        const { items, selectedItem, pageCount, pageIndex, isLoading } = this.state;

        const pagingObject = {
            data: items,
            activePage: pageIndex,
            pageCount: pageCount
        };



        return (
            <Loading isloading={isLoading}>
                <div>
                    {selectedItem ?
                        <ProductDetail item={selectedItem} onSuccessSave={() => this.onSuccessSave()} onClose={() => this.handleCloseDialog()} /> : null}
                    {items && items.length > 0 ?
                        <table className="table table-striped table-bordered" >
                        <thead>
                            <tr>
                                <th scope="col">Katalogové číslo</th>
                                <th scope="col"><a className="font-weight-bold" onClick={() => this.onClickSort('title')}>Název</a></th>
                                <th scope="col">Dostupnost</th>
                                <th scope="col">Stav</th>
                                <th scope="col">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td>{item.inStock}</td>
                                        <td>
                                            {(item.stage == 'live') ? <span className="label label-default">Live</span> : <span className="label label-default">Draft</span>}
                                            {(item.status == 'A') ? <span className="label label-default">Aktivní</span> : <span className="label label-default">Nedostupný</span>}
                                        </td>
                                        <td>
                                            {(item.stage != 'live') ?
                                                <button className="btn btn-warning" type="button" onClick={(e) => this.handleActivate(e, item.id)}>Aktivovat</button>
                                                : null
                                            }
                                            <button type="button" className="btn btn-primary" onClick={(e) => this.handleEdit(e, item)}>Upravit</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        </table> : <p>Produkty jsou prázdné</p>}
                    <PageBar data={pagingObject} onPageChanged={(page) => { this.onChangePage(page) }} />
                </div>
            </Loading>
        );
    }
}