import React, { Component } from 'react';
import { DeliveryProvider } from '../../providers/deliveryprovider.js';

export default class DeliveryDetail extends Component {

	static defaultProps = {
		tabs: [
			{
				id: 'basic',
				text: 'Základní údaje'
			},
			{
				id: 'dimension',
				text: 'Rozměry'
			}
		],
		isNew: false
	}

	state = {
		selectedtab: 'basic',
		updateLoad: false,
		isNew: false
	}



	componentWillMount() {

		this.setState({ item: this.props.item, isNew: this.props.isNew });
		this.loadTransporties();
	}

	componentDidMount() {

	}

	handleClose() {
		if (this.props.onClose)
			this.props.onClose();
	}



	loadTransporties() {
		DeliveryProvider.getTransports((data) => {

			this.setState({ transporties: data });
		});
	}

	handleUpdate(e) {
		DeliveryProvider.update(this.state.item);
	}



	toggleBlock(tab) {

		this.setState({ selectedtab: tab });
	}



	handleChange(e, property, parent) {
		var item = this.state.item;
		if (parent) {
			var parItem = item[parent];
			parItem[property] = e.target.value;
		}
		else {
			item[property] = e.target.value;
		}
		this.setState({ item: item });
	}



	render() {
		var item = this.state.item;
		return (
			<div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{item.title}</h5>
							<button type="button" className="close" data-dismiss="modal" onClick={() => this.handleClose()} aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="container-fluid">
								<form className=".form-horizontal">
									<ul className="nav nav-tabs">
										{this.props.tabs.map((tab) => {
											return <li className={(this.state.selectedtab == tab.id) ? 'active' : ''} onClick={() => this.toggleBlock(tab.id)} ><a href="#">{tab.text}</a></li>;
										})}
									</ul>
									{(this.state.selectedtab === 'basic') ?
										<div className="tab-content">
											{(this.state.isNew) ? <div className="form-group row">
												<label className="col-sm-4 col-form-label">Kód</label>
												<div className="col-sm-8">
													<select className="form-control" onChange={(e) => this.handleChange(e, 'code')} value={item.code} >

														{(this.state.transporties) ?
															this.state.transporties.map((transport) => {
																return (
																	<option value={transport.id} >{transport.transportName}</option>
																);
															})
															: null}
													</select>
												</div>
											</div> : null}
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Title</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.title} onChange={(e) => this.handleChange(e, 'title')} /></div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Cena</label>
												<div className="col-sm-8">
													<input type="number" className="form-control" value={item.price} onChange={(e) => this.handleChange(e, 'price')} /></div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Dobírka</label>
												<div className="col-sm-8">
													<input type="number" className="form-control" value={item.codPrice} onChange={(e) => this.handleChange(e, 'codPrice')} />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Doprava zdarma od</label>
												<div className="col-sm-8">
													<input type="number" className="form-control" value={item.freeLimit} onChange={(e) => this.handleChange(e, 'freeLimit')} />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Doba doručení</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.deliveryDelay} onChange={(e) => this.handleChange(e, 'deliveryDelay')} />
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-4 col-form-label">Pořadí</label>
												<div className="col-sm-8">
													<input type="text" className="form-control" value={item.priority} onChange={(e) => this.handleChange(e, 'priority')} />
												</div>
											</div>
										</div> : null}
									{(this.state.selectedtab === 'dimension') ? <div className="tab-content">
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">Velikost balíku</label>
											<div className="col-sm-8">
												<select className="form-control" onChange={(e) => this.handleChange(e, 'packageSize')}>
													<option value={'smallbox'} selected={item.packageSize === 'smallbox'}>Small Box</option>
													<option value={'bigbox'} selected={item.packageSize === 'bigbox'}>Big box</option>
												</select>
											</div>
										</div>
								
								
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">Maximální šířka</label>
											<div className="col-sm-8">
												<input type="text" className="form-control" value={item.width.max} onChange={(e) => this.handleChange(e, 'max', 'width')} />
											</div>
										</div>
								

									</div> : null}
								</form>
							</div>
						</div>

						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.handleClose()}>Close</button>


							<button type="button" className="btn btn-primary" onClick={(e) => this.handleUpdate(e)}>Uložit</button>


						</div>
					</div>
				</div>
			</div>);
	}
}