import React, { Component } from 'react';
import { ProductProvider } from '../../providers/productprovider.js';

export default class ProductSearchListBox extends Component {
	state = {
		searchValue: '',
		showEans: false
	}

	handleClick = (e) => {
		var list = this.state.searchValue.split('\n');
		ProductProvider.searchbyeanlist(list);
	}

	render() {
		return (
			<div><p>
				<span>Můžete také zadat více položek najednou jako </span>
				<a href="#" onClick={() => { this.setState({ showEans: !this.state.showEans }); }}>seznam eanů</a>
			</p>
				{this.state.showEans ?
					<div className="form-group">
						<div className="col-md-2 control-label">
							Zadejte Eany:
					</div>
						<div className="col-md-10 input-group">
							<div>
								<textarea rows="200" cols="50" style={{ maxHeight: 200 }} value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })}>
									{this.state.searchValue}</textarea>
							</div>
							<div>
								<button type="button" className="btn btn-primary" onClick={(e) => this.handleClick(e)} >Najít</button>
							</div>
						</div>
					</div> : null}
			</div>);
	}
}