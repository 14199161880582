import React, { Component } from 'react';

class LoadingButton extends Component {

	static defaultProps = {
		loading: false,
		cls:"btn btn-primary"
	}



	handleClick(e) {
		if (this.props.onClick)
			this.props.onClick();
	}


	render() {
		
		return ((!this.props.loading) ?

			<button className={this.props.cls} onClick={(e) => this.handleClick(e)} >
				{this.props.text}
			</ button>
			:
			<button type="button" className={this.props.cls}  >
				<i className="fa fa-spinner fa-spin" />Načítám...
			</ button>);

	}
}

export default LoadingButton;
	
