import React, { Component } from 'react';
import DeliveryList from '../components/delivery/deliverylist.jsx';
import { LoadingButton } from '../components/common/loadings/index.jsx';
import { DeliveryProvider } from './../providers/deliveryprovider';
import DeliveryDetail from '../components/delivery/deliverydetail.jsx';



export default class Deliveries extends Component {

	state = {

		createnew: false,
		newDelivery:null
	}

	handleAddDelivery(e) {
		DeliveryProvider.getNewDelivery((data) => {
			this.setState({ newDelivery: data, createnew:false });
		});
	}


	render() {
		return (
				<section>
					<div className="row" >
					<LoadingButton cls="btn btn-primary pull-right" text="Přidat dopravu" loading={this.state.createnew} onClick={(e) => this.handleAddDelivery(e)} />

				</div>
				{(this.state.newDelivery) ?
					<DeliveryDetail item={this.state.newDelivery} onClose={() => this.setState({ newDelivery:null })}  isNew="true" />:null}
					<DeliveryList />
				</section>
		);
	}
}