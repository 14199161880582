import React from 'react';

const BasicTab = props => (
    <div className="tab-content">
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Katalogové č.</label>
            <div className="col-sm-8">
                <input type="text" readOnly="true" className="form-control" required value={props.item.id} /></div>
        </div>
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Název</label>
            <div className="col-sm-8">
                <input type="text" className="form-control" maxLength={200} required value={props.item.title} onChange={(e) => props.onChange(e, 'title')} /></div>
        </div>
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Prodejní cena</label>
            <div className="col-sm-8">
				<input type="number" step="0.05" className="form-control" required value={props.item.price} onChange={(e) => props.onChange(e, 'price')} /></div>
        </div>
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Katalogová cena</label>
            <div className="col-sm-8">
                <input type="number" className="form-control" value={props.item.rrp} onChange={(e) => props.onChange(e, 'rrp')} /></div>
        </div>

        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Stav</label>
            <div className="col-sm-8">
                <input className="form-control" required type="text" value={props.item.displayStage} readOnly="true" /></div>
        </div>

        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Dostupnost</label>
            <div className="col-sm-8">
                <select required className="form-control" onChange={(e) => props.onChange(e, 'status', 'availability')}>
					<option value={'A'} selected={props.item.availability.status === 'A'}>Ano</option>
					<option value={'N'} selected={props.item.availability.status === 'N'}>Ne</option>
                </select>
            </div>
        </div>
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">Sklad</label>
            <div className="col-sm-8">
                <input className="form-control" required type="number" value={props.item.availability.inStock} onChange={(e) => props.onChange(e, 'inStock', 'availability')} /></div>
        </div>
     

    </div>
);

export default BasicTab;