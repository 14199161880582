import React, { Component } from 'react';
import LabelRow from './row/labelRow.jsx';
import moment from 'moment';

export default class LabelsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labels: []
        };
    }

    onChangeProperty = (value, property, index) => {
        let { selectedLabels } = this.props;
        let item = selectedLabels[index];

        item[property] = value;

        this.props.onChange(selectedLabels, 'labels');
    }

    handleAddRow = () => {
        let { labels, selectedLabels } = this.props;

        const date = moment().format('YYYY-MM-DD');

        selectedLabels.push({ from: date, to: date, label: labels[0].id, isNew: true });

        this.props.onChange(selectedLabels, 'labels');
    }

    handleDeleteRow = (index) => {
        let { selectedLabels } = this.props;

        selectedLabels.splice(index, 1);

        this.props.onChange(selectedLabels, 'labels');
    }

    render() {
        const { labels, selectedLabels } = this.props;

        return (
            <React.Fragment>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.handleAddRow()}>Přidat</button>
                {selectedLabels && selectedLabels.length > 0 ?
                    <table className="table" >
                        <thead>
                            <tr>
                                <th scope="col">Štítek</th>
                                <th scope="col">Dostupnost od</th>
                                <th scope="col">Dostupnost do</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedLabels && selectedLabels.map((label, index) => {
                                return (
                                    <LabelRow readOnly={!label.isNew} onDelete={this.handleDeleteRow} label={label} index={index} onChangeProperty={this.onChangeProperty} labels={labels} />
                                );
                            })}
                        </tbody>
                    </table> : <p>Štítky jsou prázdné</p>}
            </React.Fragment>
        );
    }
}