import React, { Component } from 'react';
import FeedItemList from '../components/product/feeditemlist.jsx';
import ProductSearchBox from '../components/product/productsearchbox.jsx';
import ProductCandidates from '../components/product/productCandidates.jsx';
import ProductSearchListBox from '../components/product/productsearchlistbox.jsx';
import ProductSearchByCodesListBox from '../components/product/productsearchbycodelistbox.jsx';
import MallProductList from '../components/product/mallproductlist.jsx';
import { Loading, LoadingButton } from '../components/common/loadings/index.jsx';
import { ProductProvider } from './../providers/productprovider';

export default class Products extends Component {

	state = {
		addMode: false,
		updateStoreLoad: false,
		UpdateCategories: false,
		updateRecomended:false
	}

	handleAdd(e) {
		this.setState({ addMode: true });
	}
	handleBack(e) {
		this.setState({ addMode: false });
	}

	handleUpdateStore(e) {
		this.setState({ updateStoreLoad: true });

		ProductProvider.updateStore((data) => {
			this.setState({ updateStoreLoad: false });
			alert("Sklad zaktualizován");

		},
			(err) => {
				this.setState({ updateStoreLoad: false });
				alert(err);
			},
			() => { this.setState({ updateStoreLoad: true }); }
		);
	}

	handleUpdateRecomended(e) {
		this.setState({ updateRecomended: true });

		ProductProvider.updateRecomended((data) => {
			this.setState({ updateRecomended: false });
			alert("Doporučené výrobky jsou zaktualizovány");

		},
			(err) => {
				this.setState({ updateRecomended: false });
				alert(err);
			},
			() => { this.setState({ updateRecomended: true }); }
		);
	}

	//handleUpdateCategories
	handleUpdateCategories(e) {
		this.setState({ UpdateCategories: true });

		ProductProvider.getCategories(true, (data) => {
			this.setState({ UpdateCategories: false });
			alert("Kategorie zauktualizovány");

		},
			(err) => {
				this.setState({ UpdateCategories: false });
				alert(err);
			},
			() => { this.setState({ UpdateCategories: true }); }
		);
	}

	render() {
		const { updateStoreLoad, addMode } = this.state;

		return (
			<Loading isLoading={updateStoreLoad}>
				{addMode ?
					<section>
						<div className="row">
							<button className="btn btn-primary pull-right" type="button" onClick={(e => this.handleBack(e))}>Zpět</button>
						</div>
						<ProductSearchBox />
						<ProductSearchByCodesListBox />
						
						<ProductSearchListBox />
						<ProductCandidates />
						<FeedItemList />

					</section>
					:
					<section>
						<div className="row">
							<div className="pull-right dropdown">
							
								<button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Servis
								 </button>
								<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<a className="dropdown-item" href="#" onClick={(e => this.handleUpdateCategories(e))}>Aktualizovat kategorie </a>

									<a className="dropdown-item" href="#" loading={this.state.updateStoreLoad} onClick={(e) => this.handleUpdateStore(e)} >Aktualizovat sklad</a>

									<a className="dropdown-item" href="#" loading={this.state.updateRecomended} onClick={(e) => this.handleUpdateRecomended(e)} >Aktualizovat doporučené výrobky</a>
								</div>
							</div>												
							<LoadingButton cls="btn btn-primary  pull-right" onClick={(e => this.handleAdd(e))} text="Přidat výrobek" />
							<LoadingButton cls="btn btn-primary  pull-right" onClick={(e => this.handleAdd(e))} text="Hledat výrobek" />
						</div>
							<MallProductList />
                    </section>
						}
            </Loading>
        );
			}
}