import React, { Component } from 'react';

import { CreditProvider } from '../../../providers/creditprovider.js';
import { BasicTab, DetailTab } from './tabs/index.jsx';

export default class CreditDetail extends Component {




    static defaultProps = {
        isNew: false,
        item: null,
        orders: null,
        tabs: [
            {
                id: 'basic',
                text: 'Obecné'
            },
            {
                id: 'detail',
                text: 'Detail'
            }
        ]
    }

    state = {
        selectedtab: 'basic',
        isNew: false,
        item: null,
        orders: null
    }

    componentWillMount() {
        const { item, isNew, orders } = this.props;

        this.setState({ item: item, isNew: isNew, orders: orders });
    }

    componentDidMount() {
        const { isNew } = this.props;
        if (isNew) {
            CreditProvider.prepareCredit((data) => {
                this.setState({ item: data});
            });
        }
    }

    handleSave = (e) => {
        const { item } = this.state;

        CreditProvider.createCredit(item, (data) => {
            if (this.props.onSuccessSave)
                this.props.onSuccessSave();
        }, (error) => {
            alert('Během ukládání nastala chyba.');
        });
    }

    handleClose() {
        if (this.props.onClose)
            this.props.onClose();
    }


    handleChangeValue = (value, property, parent) => {
        var item = this.state.item;

        if (parent) {
            var parItem = item[parent];
            parItem[property] = value;
        }
        else {
            item[property] = value;
        }

        this.setState({ item: item });
    }


    toggleBlock(tab) {
        this.setState({ selectedtab: tab });
    }

    render() {
        const { item, selectedtab } = this.state;
        const { isNew, tabs, onDetailSendMall, orders } = this.props;
        return (
            <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{isNew ? 'Přidat zápočet' : 'Editovat zápočet'}</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => this.handleClose()} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <form className=".form-horizontal">
                                    <ul className="nav nav-tabs">
                                        {!isNew ? tabs.map((tab) => {
                                            return <li className={(selectedtab == tab.id) ? 'active' : ''} onClick={() => this.toggleBlock(tab.id)} ><a href="#">{tab.text}</a></li>;
                                        }) : null}
                                    </ul>
                                    {selectedtab === 'basic' ? <BasicTab orders={orders} isNew={isNew} item={item} onChange={this.handleChangeValue} /> : null}

                                    {selectedtab === 'detail' ? <DetailTab orders={orders} /> : null}
                                </form>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.handleClose()}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={(e) => isNew ? this.handleSave(e) : onDetailSendMall(item)}>{isNew ? 'Uložit' : 'Odeslat na mall.sk'}</button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}