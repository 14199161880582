import { BaseProvider } from './baseprovider';
import { Constants } from '../store/constants';
import { AppStore } from '../store/appstore';




export class OrderProvider {

	static search(id) {
		BaseProvider.sendToServerByGet(Constants.Url.SearchOrder + "?id=" + id, (data) => {
			AppStore.emit('Orders.Update', data);
		});
	}
	////checkOrders
	static updateUnConfirmed(callback, errorback) {
		BaseProvider.sendToServerByGet(Constants.Url.UpdateUnConfirmed, () => {
			callback();
		},
			(error) => {
				errorback(error);
			});
	}


	static checkOrders(callback, errorback) {
		BaseProvider.sendToServerByGet(Constants.Url.CheckOrders, () => {
			callback();
		},
		(error) => {
			errorback(error);
		});
	}

	static getOrder(id, callBack) {
		BaseProvider.sendToServerByGet(Constants.Url.Order + "?id=" + id, (data) => {
			if (callBack) {
				callBack(data);
			}
		});
	}

	static getOrders(postData,success) {
		BaseProvider.sendToServerByPost(postData,Constants.Url.Orders, (data) => {
			AppStore.emit('Orders.Update', data);
			if (success) {
				success();
			}
		});
	}
	

	static getStates(status, callback) {
		BaseProvider.sendToServerByGet(Constants.Url.OrderStates + "?state=" + status, (data) => {
			callback(data);
		});
	}

	static update(item, callBack, errorBack, proccessBack) {
		BaseProvider.sendToServerByPost(item, Constants.Url.OrderUpdate, (data) => {
			if (callBack) {
				callBack(data);
			}
		}, null, () => {
			if (proccessBack)
				proccessBack();
		});
	}
}