import React from 'react';

const EnhancedTab = props => (
	<div className="tab-content">
		<div className="form-group row">
			<label className="col-sm-4 col-form-label">EAN</label>
			<div className="col-sm-8">
				<input className="form-control" type="text" value={props.item.barcode} onChange={(e) => props.onChange(e, 'barcode')} /></div>
		</div>
		<div className="form-group row">
			<label className="col-sm-4 col-form-label">Hlavní obrázek</label>
			<div className="col-sm-8">
				<input className="form-control" type="text" value={props.item.defaultPicture} onChange={(e) => props.onChange(e, 'defaultPicture')} />
			</div>
		</div>
		<div className="form-group row">
			<label className="col-sm-4 col-form-label">Priorita</label>
			<div className="col-sm-8">
                <input className="form-control" type="number" required value={props.item.priority} onChange={(e) => props.onChange(e, 'priority')} /></div>
		</div>
		<div className="form-group row">
			<div className="col-sm-10 checkbox">
				<label className=" col-form-label">
					<input className="" type="checkbox" value={props.item.freeDelivery} onChange={(e) => props.onChange(e, 'freeDelivery')} />
					Doručení zdarma
				</label>
			</div>
		</div>
	</div>
);


export default EnhancedTab;
