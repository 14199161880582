import React from 'react';
import { InputFormattedText } from '../../../common/inputs/index.jsx';

const DescriptionTab = props => (
	<div className="tab-content">
		<label>Krátký popis</label>
		<div className="form-group row">
			<div className="col-sm-16">

                <textarea required rows="5" style={{ 'minWidth': '100%' }} maxLength={300} Value={props.item.shortdesc} label={"Krátký popis"} onChange={(e) => props.onChange(e.target.value, 'shortdesc')} >{props.item.shortdesc}</textarea>
			</div>
        </div>
        <InputFormattedText required defaultValue={props.item.longdesc} maxLength={13000} label={"Popis"} onChange={(e) => props.onChange(e, 'longdesc')} />
	</div>
)

export default DescriptionTab;
